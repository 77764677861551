import { RangeSetBuilder } from '@codemirror/state'
import {
  Decoration,
  type EditorView,
  ViewPlugin,
  ViewUpdate,
} from '@codemirror/view'
import { type Change as DiffChange } from 'diff'

type Change = DiffChange & { startLineNumber: number; endLineNumber: number }

const removeLineDecoration = Decoration.line({
  attributes: { class: 'vt-diff-remove-line' },
})

const addLineDecoration = Decoration.line({
  attributes: { class: 'vt-diff-add-line' },
})

const getDiffDecorations = (changes: Change[], view: EditorView) => {
  const builder = new RangeSetBuilder<Decoration>()

  for (const change of changes) {
    if (!change.added && !change.removed) {
      continue
    }

    const endLineNumber = Math.min(change.endLineNumber, view.state.doc.lines)

    for (
      let currentLineNumber = change.startLineNumber;
      currentLineNumber <= endLineNumber;
      currentLineNumber += 1
    ) {
      const line = view.state.doc.line(currentLineNumber)

      if (change.added) {
        builder.add(line.from, line.from, addLineDecoration)
      } else if (change.removed) {
        builder.add(line.from, line.from, removeLineDecoration)
      }
    }
  }

  const result = builder.finish()

  return result
}

export const diffViewer = (changes: Change[]) => {
  return ViewPlugin.define(
    (view: EditorView) => {
      return {
        decorations: getDiffDecorations(changes, view),
        update(update: ViewUpdate) {
          if (update.docChanged || update.viewportChanged) {
            this.decorations = getDiffDecorations(changes, update.view)
          }
        },
      }
    },
    {
      decorations: (plugin) => {
        return plugin.decorations
      },
    }
  )
}
